<template>
  <div class="row align-items-center">
    <div class="col">
      <h4>
        {{ $t("categories.categories") }}
      </h4>
    </div>
    <div class="col-auto text-end mb-3">
      <button
        class="btn btn-type-2 btn-small"
        v-on:click="categoryModal({ id: 0 })"
      >
        <span class="regular-12">
          {{ $t("categories.newCategory") }}
        </span>
      </button>
    </div>
  </div>
  <nested-draggable
    :categories="categories"
    :parent-id="0"
    v-on:addCategory="categoryModal"
    v-on:editCategory="(e, f) => categoryModal(e, f, true)"
    v-on:moveEvent="moveEvent"
    v-on:delete="categoryDeleteModal"
    :key="categoryKey"
  />
  <ConfirmModal
    ref="categoryDeleteModal"
    :title="$t(`categories.deleteCategory`)"
    :text="`<ul><li>You will delete ${deleteCategoryObject?.name}<b></li><li>The products in this category will be uncategorized</li><li>You can't undo this action</li></ul><p>Are you sure you want to delete?</p>`"
    :cancel-button="`No, keep ${deleteCategoryObject?.name}`"
    :ok-button="`Yes, delete ${deleteCategoryObject?.name}`"
    v-on:success="deleteCategory"
  />
  <CategoryModal
    ref="addCategoryModal"
    v-model:active-languages="activeLanguagesData"
    v-model:selected-language="selectedLanguageData"
    v-model:edit-category="editCategory"
    v-model:vat-rates="vatRates"
    v-model:measurements="measurements"
    v-on:addNewCategory="addNewCategory"
    v-on:saveCategory="saveCategory"
  />
</template>

<script>
import nestedDraggable from "./nested";
import http from "../../../modules/http";
import ConfirmModal from "../../modals/ConfirmModal";
import CategoryModal from "../../modals/CategoryModal";

export default {
  name: "Categories",
  components: { CategoryModal, ConfirmModal, nestedDraggable },
  props: {
    activeLanguages: Array,
    selectedLanguage: String,
    type: String,
  },
  data() {
    return {
      activeLanguagesData: null,
      selectedLanguageData: null,
      loadAddBtn: false,
      deleteCategoryObject: {},
      selectedCategory: null,
      editCategory: false,
      categories: null,
      vatRates: null,
      measurements: null,
      categoryKey: 0,
    };
  },
  watch: {
    activeLanguages() {
      this.activeLanguagesData = this.activeLanguages;
    },
    selectedLanguage() {
      this.selectedLanguageData = this.selectedLanguage;
    },
    type() {
      this.load();
    },
  },
  mounted() {
    this.activeLanguagesData = this.activeLanguages;
    this.selectedLanguageData = this.selectedLanguage;
    this.load();
  },
  methods: {
    load() {
      http
        .fetch(`/products/${this.type}/categories?language=en`)
        .then((data) => {
          this.categories = data.data;
        });
    },
    moveEvent() {
      let data = this.categoryPosition(this.categories, 0, 0);
      http.fetch(
        `/products/${this.type}/categories/order`,
        { data: data },
        true
      );
    },
    categoryDeleteModal(ch) {
      this.deleteCategoryObject = ch;
      this.$refs.categoryDeleteModal.showModal();
    },
    deleteCategory() {
      http
        .fetch(
          `/products/${this.type}/categories/delete`,
          { id: this.deleteCategoryObject.id },
          true
        )
        .then(() => {
          let e = this.categories.indexOf(this.deleteCategoryObject);
          if (e > -1) {
            this.categories.splice(e, 1);
          }
          delete this.deleteCategoryObject;
          this.categoryKey++;
        });
    },

    saveCategory(addCategory) {
      console.log(addCategory);
      http
        .fetch(`/products/${this.type}/categories/edit`, addCategory, true)
        .then(() => {
          this.$refs.addCategoryModal.hideModal();
          this.selectedCategory.name = addCategory.name["en"];
          if (this.selectedCategory.categories === undefined) {
            this.selectedCategory.categories = [];
          }
        });
    },
    addNewCategory(addCategory) {
      http
        .fetch(`/products/${this.type}/categories/add`, addCategory, true)
        .then((data) => {
          this.$refs.addCategoryModal.hideModal();
          if (this.selectedCategory.categories === undefined) {
            this.selectedCategory.categories = [];
          }
          this.selectedCategory.categories.unshift(data);
          this.categoryKey++;
        });
    },
    categoryPosition(row, i, sub) {
      let data = [];
      for (const cat of row) {
        console.log(i);
        data.push({
          id: cat.id,
          position: i++,
          parent: sub,
        });
        if (cat.categories !== undefined) {
          data = data.concat(this.categoryPosition(cat.categories, i, cat.id));
          i = i + data.length;
        }
      }

      return data;
    },

    categoryModal(category, subs = null, editCategory = false) {
      this.editCategory = editCategory;
      let cat = this;

      if (this.vatRates === null) {
        http.fetch("/vat").then((data) => {
          this.vatRates = data;
        });
      }
      if (this.vatRates === null) {
        http.fetch("/measurements").then((data) => {
          this.measurements = data;
        });
      }

      if (category.id > 0) {
        subs = subs.reverse();
        subs.push(category.id);
        for (const sub of subs) {
          if (sub === 0) {
            continue;
          }
          cat = cat.categories.filter((e) => e.id === sub)[0];
        }
      }
      this.selectedCategory = cat;

      if (editCategory) {
        http
          .fetch(`/products/${this.type}/categories/${category.id}`)
          .then((data) => {
            this.$refs.addCategoryModal.addCategory = data;
          });
      } else {
        this.$refs.addCategoryModal.addCategory.parent = cat.id;
      }

      this.$refs.addCategoryModal.showModal();
    },
  },
};
</script>
