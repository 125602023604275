<template>
  <draggable
    class="dragArea"
    tag="ul"
    :list="categories"
    :group="{ name: 'g1' }"
    item-key="name"
    v-on:end="moveEvent"
    v-bind="dragOptions"
  >
    <template #item="{ element }">
      <li>
        <div class="row item align-items-center">
          <div class="col">{{ element.name }}</div>
          <div class="col-auto text-end">
            <button
              class="btn btn-outline-light btn-sm"
              v-on:click="editCategory(element)"
            >
              <BaseIcon name="edit" />
              <span class="text-black regular-12">
{{ $t("editOnClick.edit") }}
</span>
            </button>
            <button
              class="btn btn-outline-light btn-sm"
              v-on:click="addCategory(element)"
            >
              <BaseIcon name="add" />
              <span class="text-black regular-12">
{{ $t("nested.newSubcategory") }}
</span>
            </button>
            <button
              class="btn btn-outline-light btn-sm"
              v-on:click="deleteEvent(element)"
            >
              <BaseIcon name="trash" />
              <span class="text-black regular-12">
{{ $t("nested.delete") }}
</span>
            </button>
          </div>
        </div>
        <nested-draggable
          :categories="element.categories"
          :parent-id="element.id"
          v-on:moveEvent="moveEvent"
          v-on:addCategory="addCategory"
          v-on:editCategory="editCategory"
          v-on:delete="deleteEvent"
        />
      </li>
    </template>
  </draggable>
</template>
<script>
import { defineAsyncComponent } from "vue";
import draggable from "vuedraggable";
const BaseIcon = defineAsyncComponent(() => import("../../icons/BaseIcon.vue"));
export default {
  props: {
    categories: {
      required: true,
      type: Array,
    },
    parentId: {
      required: true,
      type: Number,
    },
  },
  components: {
    BaseIcon,
    draggable,
  },
  emits: [
    "update:modelValue",
    "addCategory",
    "editCategory",
    "moveEvent",
    "delete",
  ],
  methods: {
    moveEvent() {
      this.$emit("moveEvent");
    },
    deleteEvent(obj) {
      this.$emit("delete", obj);
    },
    addCategory(element, subs = null) {
      if (subs === null) {
        subs = [];
      }
      subs.push(this.parentId);
      this.$emit("addCategory", element, subs);
    },
    editCategory(element, subs = null) {
      if (subs === null) {
        subs = [];
      }
      subs.push(this.parentId);
      this.$emit("editCategory", element, subs);
    },
  },
  mounted() {
    for (const key in this.categories) {
      if (this.categories[key].categories === undefined) {
        let a = this.categories;
        a[key].categories = [];
        this.$emit("update:modelValue", a);
      }
    }
  },
  data() {
    return {
      dragOptions: {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      },
    };
  },
  name: "nested-draggable",
};
</script>
<style scoped>
ul {
  list-style-type: none;
}
.dragArea {
  min-height: 2px;
}
li .item {
  border-bottom: 1px solid #d7d7d7;
  padding: 0.3em 0;
  margin-left: 0;
  margin-right: 0;
}
</style>
